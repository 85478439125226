<template>
  <!--New-->
  <div v-if="auth.interfaces != 0 && viewMode == 0" class="nw-button-add" @click="newItem">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="12" y1="8" x2="12" y2="16"></line>
      <line x1="8" y1="12" x2="16" y2="12"></line>
    </svg>
  </div>

  <div class="nw-page">

    <div class="nw-page-main__side">
    </div>

    <main v-if="renderComponent">
      <h1 class="nm-head">
        HI-BI News
      </h1>

      <div v-if="viewMode == 0">
        <NewsList />
      </div>

      <div v-if="viewMode > 0">
        <NewsItem :isInitialEdit="isEdit" :newsId="newsId" />
      </div>

    </main>


    <div class="nw-page-main__side">
    </div>

  </div>

</template>

<script>
import { mapState } from 'vuex';
import NewsList from '@/components/news/NewsList.vue';
import NewsItem from '@/components/news/NewsItem.vue';
import { useHead } from '@vueuse/head';

export default {
  name: 'News',
  data () {

    return {
      renderComponent: true,

      viewMode: 0,  //0 - List, 1 - Item
      isEdit: false,
      newsId: 0
    }
  },
  
  components: {
    NewsList,
    NewsItem
  },
  
  beforeCreate () {

    useHead(
      {        title: 'K3N',
        link: [
          {
            rel: 'canonical',
            href: 'https://k3n.aiviron.com/news/all'
          },
          {
            rel: 'icon',
            href: '/favicon.ico'
          }
        ],
        meta: [
          { name: 'description', content: 'HI-BI. Новости интересов личной и деловой жизни: управление, знания, информационные технологии, архитектура, дизайн'},

          { property: 'og:locale', content: 'ru-ru'},
          { property: 'og:type', content: 'article'},
          { property: 'og:title', content: 'HI-BI. Новости интересов личной и деловой жизни'},
          { property: 'og:description', content: 'Коммуникации, знания, информационные технологии, архитектура, дизайн'},
          { property: 'og:url', content: 'https://k3n.aiviron.com/news/all'},
          { property: 'og:image', content: '/favicon.ico'},
          { property: 'og:site_name', content: 'K3N'},
        ],

      }
    )

  },

  async created () {
    try {

      await this.$store.dispatch('set_interfaces')

      this.$watch(
        () => this.$route.params,
        (toParams) => {
          // react to route changes...
          if (toParams.id === 'all') {
            this.viewMode = 0;
            this.isEdit = false;
            this.newsId = 0;
            this.forceRerender();
          }
          if (toParams.id === 'new') {
            this.viewMode = 1;
            this.isEdit = true;
            this.newsId = 0;
            this.forceRerender();
          }
          if (parseInt(toParams.id) > 0) {
            this.viewMode = 1;
            this.isEdit = false;
            this.newsId = parseInt(toParams.id);
            this.forceRerender();
          }

        }
      )

      const paramsId = this.$router.currentRoute._value.params.id;

      switch (paramsId) {
        case 'all':
          this.viewMode = 0;
          this.isEdit = false;
          this.newsId = 0;
          
          break;
        case 'new':
          this.viewMode = 1;
          this.isEdit = true;
          this.newsId = 0;
          
          break;
      
        default:
          this.newsId = parseInt(paramsId);    
          if (this.newsId > 0) {
            this.viewMode = 1;
            this.isEdit = false;
          } else {
            this.viewMode = -1;
          }

          break;
      }
      
    } catch (error) {
      console.error('News created error:', error)
    }
    
  },

  computed: {
    ...mapState({
      auth: state => state.auth
    }),
  },

  methods: {

    newItem () {
      this.$router.push('/news/new');
    },

    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true
      })
    },

  }

}
</script>

<style lang="scss" scoped>
  @import '@/assets/news_2.scss';
</style>
