<template>
  <div class="ev-layout">
    <div class="ev-page-width">

      <div v-if="auth.interfaces != 0">
        <!--New-->
        <div v-if="!isUpload && !isView" class="ev-button-add" @click="setUpload" title="Add event">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="8" x2="12" y2="16"></line>
            <line x1="8" y1="12" x2="16" y2="12"></line>
          </svg>
        </div>
        <!--Close-->
        <div v-else class="ev-button-close" @click="isUpload=false; isView=false" title="Close">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="15" y1="9" x2="9" y2="15"></line>
            <line x1="9" y1="9" x2="15" y2="15"></line>
          </svg>
        </div>
      </div>

      <div class="ev-page__wrapper">
        <section v-if="isView && renderComponent" class="ev-section__event-view">
          <EventView :eventId="eventId"/>
        </section>

        <section v-if="isUpload && renderComponent" class="ev-section__event-upload">
          <EventUpload :eventId="eventId"/>
        </section>

        <section class="ev-section__events-view">
          <EventsView />
        </section>

      </div>

    </div>
  </div>

</template>

<script>
import EventsView from '@/components/theme/CalendarView.vue'
import EventView from '@/components/theme/EventView.vue'
import EventUpload from '@/components/theme/EventUpload.vue'
import { useHead } from '@vueuse/head'
import { mapState } from 'vuex';

import emitter from '@/emitter.js'

export default {
  name: 'Events',
  components: { 
    EventsView,
    EventView,
    EventUpload 
  },
  data () {
    return {
      renderComponent: true,
      isUpload: false,
      isView: false,
      eventId: 0
    }
  },

  beforeCreate () {

    useHead(
      {
        title: 'K3N',
        link: [
          {
            rel: 'canonical',
            href: 'https://k3n.aiviron.com/calendar'
          },
          {
            rel: 'icon',
            href: '/favicon.ico'
          }
        ],
        meta: [
          { name: 'description', content: 'HI-BI. Календарь личной и деловой жизни: управление, знания, информационные технологии, спорт (спортивное ориентирование, бег)'},

          { property: 'og:locale', content: 'ru-ru'},
          { property: 'og:type', content: 'article'},
          { property: 'og:title', content: 'HI-BI. Календарь личной и деловой жизни'},
          { property: 'og:url', content: 'https://k3n.aiviron.com/calendar'},
          { property: 'og:image', content: '/favicon.ico'},
          { property: 'og:description', content: 'Коммуникации, знания, информационные технологии, архитектура, дизайн'},
          { property: 'og:site_name', content: 'K3N'},
        ],

      }
    )

  },


  async created () {
    try {
      await this.$store.dispatch('set_interfaces')
      //
    } catch (error) {
      console.log('created Event error:', error)
    } finally {
      emitter.all.delete('viewEvent')
      emitter.on('viewEvent', (event) => this.viewEvent (event.id))

      emitter.all.delete('editEvent')
      emitter.on('editEvent', (event) => this.editEvent (event.id))
    }
  },

  computed: {
    ...mapState({
      auth: state => state.auth
    }),
  },

  methods: {
    setUpload () {
      this.eventId = 0
      this.isUpload = true
      this.isView = false
    },

    editEvent (id) {
      this.eventId = id
      this.isUpload = true
      this.isView = false

      this.forceRerender ()
    },
    viewEvent (id) {
      this.eventId = id
      this.isView = true
      this.isUpload = false

      this.forceRerender ()
    },

    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true
      })
    }

  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/events.scss'
</style>
